import React, { useEffect } from 'react'
import Layout from '../../components/help/Layout/Layout'
import Seo from '../../components/help/Seo'
import { navigate } from 'gatsby'

const CompanyPage = () => {
    useEffect(() => {
        navigate(`/company/about/`)
    }, [])

    return (
        <Layout>
            <Seo title='Company' />
            <p>Welcome to page - Company</p>
        </Layout>
    )
}

export default CompanyPage
